<!-- 查看详情 -->

<template>
  <div>
    <back />
    <!-- 支付二维码 -->
    <div class="detail">
      <header>支付二维码</header>
      <div class="form">
        <!-- :callback="qrCodeCallback" 生成的二维码 Data URI 可以在回调中取得,第一个参数为二维码 data URL, 第二个参数为 props 传过来的 qid(因为二维码生成是异步的,所以加个 id 用于排序)-->
        <vue-qr ref="qrCode" :text="qrcode" :logoSrc="logoSrc" :color-dark="randomColor" width="270"
          height="270"></vue-qr>
        <div class="tips">支付金额：{{ obj.fee }} 元</div>
        <div class="tips"><i class="el-icon-success"></i>请使用微信扫码支付！</div>
      </div>
      <!-- 缴费查询弹窗 -->
      <el-dialog title="缴费查询" :visible.sync="dialogVisible" :show-close="false" :close-on-click-modal="false"
        :close-on-press-escape="false" center>
        <el-row>
          <el-col>
            <!-- <el-result v-if="flag == 'FLAG_PAY_PAYING'" icon="warning" title="支付中!"
              subTitle="当前正在缴费中，请稍后或刷新页面后重新扫码"></el-result> -->
            <!-- <el-result v-if="flag == 'error'" icon="error" title="支付失败!" subTitle="系统查询到您未支付哦"></el-result> -->
            <el-result v-if="flag == 'error'" icon="error" title="支付失败!" subTitle="请稍后或刷新页面后重新扫码"></el-result>
            <el-result v-if="flag == 'success'" icon="success" title="支付成功!" subTitle="恭喜您已缴费成功"></el-result>
          </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="handleCloseDialog">确 认</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="Btn">
      <!-- <el-button type="" @click="reloadPage">刷新二维码</el-button> -->
      <el-button type="primary" @click="onSubmit" :loading="btnLoading">我已支付</el-button>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      obj: {},
      payCode: '',//token
      // flag: ['FLAG_PAY_PAYING', 'FLAG_PAY_FAILD', 'FLAG_PAY_SUCCESS'],
      flag: '',
      dialogVisible: false,
      btnLoading: false,
      qrcode: '', // 二维码的内容，即扫出来的结果
      logoSrc: '', // 嵌入二维码中心的logo图片地址
      randomColor: 'black', // 实点的颜色，即有色部分。修改该值可改变实点的颜色，即二维码整体展示的颜色
      qrCodeUrl: '', // 存放生成的二维码url
    }
  },
  created() {
    this.obj = this.$store.state.payQuery
    this.getQrCode()
  },
  methods: {
    // 获取支付二维码
    getQrCode() {
      this.$axios.post(this.$api.payInfo, {
        sourceId: this.obj.sourceId, // 对应平台的主键id
        fee: this.obj.fee, // 缴费金额
        sourceType: this.obj.sourceType, // 支付来源：1平台应标资格；2独立标应标资格；3保证金
        supplierId: this.obj.supplierId, // 供应商id
        title: this.obj.sourceType == 3 ? '履约保证金' : '应标资格金额', // 付款标题
      }).then((res) => {
        this.qrcode = ''
        const { code, result } = res.data
        if (code == 100) {
          this.qrcode = result.qrurl
          this.payCode = result.payCode
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    // 重新获取二维码
    reloadPage() {
      location.reload()
    },
    // 已支付
    onSubmit() {
      this.btnLoading = true
      this.$axios.post(this.$api.select_pay, {
        payCode: this.payCode
      }).then((res) => {
        this.dialogVisible = true;
        const { code } = res.data
        if (code == 100) {
          this.flag = 'success'
          this.$store.commit('setPayQuery', null)
        }
        this.btnLoading = false;
      }).catch(() => {
        this.dialogVisible = true;
        this.flag = 'error'//支付失败，请稍后或刷新页面后重新扫码
        this.btnLoading = false;
      });
    },
    handleCloseDialog() {
      switch (this.flag) {
        case 'error':
          // 支付失败
          this.dialogVisible = false;
          break;
        case 'success':
          // 支付成功
          if (this.obj.aaa || this.obj.aaa == 1) {
            this.$router.go(-2)
          } else {
            this.$router.go(-1)
          }
          break;
      }
    },
  },
}
</script>

<style scoped lang='scss'>
.detail {
  // height: 243px;
  border-radius: 6px 6px 6px 6px;
  overflow: hidden;
  margin-bottom: 20px;

  header {
    height: 45px;
    border-radius: 6px 6px 0px 0px;
    border-bottom: 1px solid #E2E2E2;
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: bold;
    font-size: 16px;
    color: #1A1A1A;
    line-height: 45px;
    text-align: center
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 550px;
    padding: 20px;
    box-sizing: border-box;

    .qrcode {
      width: 270px;
      height: 270px;
    }

    .tips {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      margin-top: 20px;

      .el-icon-success {
        color: #06B464;
        font-size: 26px;
        margin-right: 6px;
      }
    }
  }
}

.Btn {
  text-align: center;
}
</style>